// src/App.js

import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Avatar, Button, CircularProgress } from '@mui/material';
import logo from './assets/images/codersports-logo.png';

// Lazy load pages for code splitting
const HomePage = lazy(() => import('./pages/HomePage'));
const ProjectForm = lazy(() => import('./pages/ProjectForm'));
const AboutPage = lazy(() => import('./pages/AboutPage'));
const SuccessPage = lazy(() => import('./pages/SuccessPage')); // Import SuccessPage

function App() {
  return (
    <BrowserRouter
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}
    >
      <AppBar position="static" sx={{ backgroundColor: 'primary.main' }}>
        <Toolbar>
          <Avatar
            alt="CoderSports Logo"
            src={logo}
            sx={{ marginRight: 2, width: 40, height: 40 }}
          />
          <Typography
            variant="h6"
            component={NavLink}
            to="/"
            end
            sx={{
              flexGrow: 1,
              textDecoration: 'none',
              color: 'inherit'
            }}
          >
            CoderSports
          </Typography>

          <div>
            <Button
              component={NavLink}
              to="/"
              end
              sx={{
                color: '#fff',
                marginLeft: 1,
                textTransform: 'none',
                borderBottom: '2px solid transparent',
                '&.active': {
                  borderBottom: '2px solid #FFD700',
                },
              }}
            >
              Home
            </Button>
            <Button
              component={NavLink}
              to="/project"
              sx={{
                color: '#fff',
                marginLeft: 1,
                textTransform: 'none',
                borderBottom: '2px solid transparent',
                '&.active': {
                  borderBottom: '2px solid #FFD700',
                },
              }}
            >
              Project
            </Button>
            <Button
              component={NavLink}
              to="/about"
              sx={{
                color: '#fff',
                marginLeft: 1,
                textTransform: 'none',
                borderBottom: '2px solid transparent',
                '&.active': {
                  borderBottom: '2px solid #FFD700',
                },
              }}
            >
              About
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      {/* Suspense fallback for lazy-loaded pages */}
      <Suspense
        fallback={
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
            <CircularProgress />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/project" element={<ProjectForm />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/success" element={<SuccessPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
