// src/theme.js

import { createTheme } from '@mui/material/styles';

// Create a custom theme instance
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Primary color (blue)
      contrastText: '#fff', // Text color on primary backgrounds
    },
    secondary: {
      main: '#dc004e', // Secondary color (pink)
      contrastText: '#fff', // Text color on secondary backgrounds
    },
    background: {
      default: '#f4f4f4', // Default background color
      paper: '#fff', // Background color for paper components
    },
    text: {
      primary: '#333333', // Primary text color
      secondary: '#555555', // Secondary text color
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif', // Default font family
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
      letterSpacing: '-0.05rem',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
      letterSpacing: '-0.05rem',
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 700,
      letterSpacing: '-0.05rem',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.43,
    },
    // Add more typography variants as needed
  },
  components: {
    // Example: Override styles for MuiButton
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none', // Disable uppercase transformation
        },
      },
    },
    // Example: Override styles for MuiAppBar
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#1976d2', // Match primary color
        },
      },
    },
    // Add more component overrides as needed
  },
});

export default theme;
